const axios = require("axios");
const { baseAPI } = require("../config/config.json");

const saveAuth = (data) => {
  if (typeof window === "undefined") return;
  window.localStorage.setItem("user", JSON.stringify(data));
};

const getToken = () => {
  if (typeof window === "undefined") return;
  const user = window.localStorage.getItem("user");
  if (!user) return "";
  return JSON.parse(user).jwt;
};

const getUser = () => {
  if (typeof window === "undefined") return;
  const user = window.localStorage.getItem("user");
  if (!user) return "";
  return JSON.parse(user).user;
};

const refreshUser = (newUser) => {
  if (typeof window === "undefined") return;
  const user = window.localStorage.getItem("user");
  if (!user) return "";
  const obj = JSON.parse(user);
  obj.user = newUser;
  window.localStorage.setItem("user", JSON.stringify(obj));
};

const clearAuth = () => {
  if (typeof window === "undefined") return;
  window.localStorage.removeItem("user");
};

const uploadFile = (file) => {
  const formData = new FormData();
  formData.append("files", file);
  return axios.post(`${baseAPI}/upload`, formData);
};

const uploadFilesMulti = async (files, name_prefix = "") => {
  const formData = new FormData();
  let fileInfo = [];
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    formData.append("files", file);
    //add name_prefix to file.name before its extension
    fileInfo.push({ name: name_prefix + "_" + file.name, caption: name_prefix });

  }
  if (fileInfo.length === 1) {
    formData.append("fileInfo", JSON.stringify(fileInfo[0]));
  } else {
    formData.append("fileInfo", JSON.stringify(fileInfo));
  }

  return axios.post(`${baseAPI}/upload`, formData);
};

const uploadContractPDF = (file) => {
  const formData = new FormData();
  formData.append("files.file", file);
  formData.append("data", '{"file":"trustiway"}');
  return axios.post(`${baseAPI}/commerciales/upload_contrat`, formData);
};

const signContractPDF = (devis) => {
  return axios.post(`${baseAPI}/commerciales/sign_contrat`, {
    id: devis.id,
    model_type: devis.model_type,
  });
};

const getCommercialId = () => {
  return getUser().id;
};

const getBinomeId = () => {
  return getUser().binome_id;
};

const getUserId = () => {
  return getUser().id;
};

const hasAdminRole = () => {
  return getUser()?.role?.type === "super_admin" || false;
};

const hasClassiqueManagerRole = (user) => {
  let role = (user || getUser())?.role;
  return role?.type === "manager_classique";
};

const hasCreditManagerRole = () => {
  return getUser()?.role?.type === "manager_credit";
};

const isCommercialClassique = (user) => {
  let role = (user || getUser())?.role;
  return (
    role?.type === "commerciale_classique" ||
    role?.type === "commerciale_classique_junior"
  );
};

const isCommercialVoyage = () => {
  return getUser()?.role?.type === "commerciale_voyage";
};

const hasVoyageManagerRole = () => {
  return getUser()?.role?.type === "manager_voyage";
};

const hasAdminAnalysteRole = (user) => {
  return hasAdminRole() || hasChefAnalysteRole(user);
}

const hasAdministrativeRole = () => {
  return (
    getUser()?.role?.type === "administrative_trustiway" ||
    hasAdminRole() ||
    hasComptableRole()
  );
};

const hasComptableRole = () => {
  return getUser()?.role?.type === "comptable_trustiway";
};

const hasOnlyAdministrativeRole = (user) => {
  const role = (user || getUser())?.role;
  return role?.type === "administrative_trustiway";
};

const hasRightToViewVoyage = () => {
  return (
    hasAdministrativeRole() || hasVoyageManagerRole() || isCommercialVoyage()
  );
};

const hasRightToViewCommissions = () => {
  return hasAdministrativeRole() || hasClassiqueManagerRole();
};

const saveContextBot = (data) => {
  if (typeof window === "undefined") return;
  window.localStorage.setItem("context", JSON.stringify(data));
};

const getContextBot = () => {
  if (typeof window === "undefined") return;
  const context = window.localStorage.getItem("context");
  if (!context) return null;
  return JSON.parse(context);
};

const clearContextBot = () => {
  if (typeof window === "undefined") return;
  window.localStorage.removeItem("context");
};

const isProductionMode = () => baseAPI.includes("api.trustiway.com");

const hasPreAnalysteRole = (user) => {
  let role = (user || getUser())?.role;
  return role?.type === "pre_analyste";
}

const hasAnalysteRole = (user) => {
  let role = (user || getUser())?.role;
  return role?.type === "analyste";
}

const hasChefAnalysteRole = (user) => {
  let role = (user || getUser())?.role;
  return role?.type === "chef_analyste";
}

module.exports = {
  getToken,
  getUser,
  getUserId,
  saveAuth,
  clearAuth,
  uploadFile,
  getCommercialId,
  uploadContractPDF,
  signContractPDF,
  hasAdminRole,
  hasVoyageManagerRole,
  hasClassiqueManagerRole,
  hasAdministrativeRole,
  isCommercialClassique,
  saveContextBot,
  getContextBot,
  clearContextBot,
  isProductionMode,
  hasOnlyAdministrativeRole,
  hasComptableRole,
  hasRightToViewVoyage,
  hasCreditManagerRole,
  refreshUser,
  uploadFilesMulti,
  hasRightToViewCommissions,
  getBinomeId,
  hasPreAnalysteRole,
  hasAnalysteRole,
  hasChefAnalysteRole,
  hasAdminAnalysteRole
};
